import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../store/cartSlice";
import { clearDate } from "../store/dateSlice";
import { useNavigate } from "react-router-dom";

const DateChecker = () => {
  const eventDate = useSelector((state) => state.eventDate.date); // Access event date from Redux
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const checkEventDate = () => {
      if (!eventDate) return; // No event date to check

      const today = new Date();
      const event = new Date(eventDate);

      const diffTime = event - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

      if (diffDays < 45) {
        alert("Event date must be at least 45 days in advance.");
        dispatch(clearCart());
        dispatch(clearDate());
        navigate("/date"); // Redirect to calendar selection page
      }
    };

    // Initial check
    checkEventDate();

    // Set interval to run the check every hour
    const intervalId = setInterval(() => {
      checkEventDate();
    }, 60 * 60 * 1000); // Run every 60 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [eventDate, dispatch, navigate]);

  return null; // This component doesn't render anything
};

export default DateChecker;
