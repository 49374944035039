// src/pages/FAQ.jsx
import React from "react";
import "./faq.css";

const FAQ = () => {
  return (
    <div className="faq-container">
      <h1>F.A.Q.</h1>
      <div className="faq-item">
        <h2>What is Priorum?</h2>
        <p>Priorum is a dynamic pricing application for event rentals.</p>
      </div>
      <div className="faq-item">
        <h2>What does Priorum do?</h2>
        <p>
          Priorum offers reduced pricing for event rentals according to your
          event date.
        </p>
      </div>
      <div className="faq-item">
        <h2>How does Priorum work?</h2>
        <p>
          Once you enter your event date, Priorum calculates reduced pricing
          based off of the quantities available at that time for the items you
          will need for your event.
        </p>
      </div>
      <div className="faq-item">
        <h2>Why should I use Priorum?</h2>
        <p>
          Reserve as early as possible for the biggest discounts. When you book
          with Priorum, you’ll have access to event rental equipment at deep
          discounts. The earlier you place your rental reservation, the more
          items you will find at the highest discounts.
        </p>
      </div>
      <div className="faq-item">
        <h2>
          When should I use Priorum? How far out from my event date should I
          check it?
        </h2>
        <p>
          It’s never too early to book with Priorum! As soon as you have an
          event date confirmed with an estimated guest count, place your order.
          All Priorum reservations must be made at least forty five days in
          advance of your event.
        </p>
      </div>
      <div className="faq-item">
        <h2>How does Priorum calculate discounts?</h2>
        <p>
          Discount tiers are based on the quantity of the item that we have
          available on the date of your event. Once a quantity allocation for a
          discount tier is exhausted, the discount goes away.
        </p>
      </div>
      <div className="faq-item">
        <h2>What if I still have questions or need help?</h2>
        <p>
          Please email inquiries@fulcrumgroup.events, and we will get back to
          you as soon as possible!
        </p>
      </div>
      <div className="faq-item">
        <h2>
          SPECIAL PRIORUM DISCOUNTED PRODUCT DEPOSIT AND EXTENDED RENTAL FEE
          POLICY
        </h2>
        <p>
          A 50% deposit becomes non-refundable immediately for Priorum
          discounted products. Should you need to cancel an item ordered at a
          Priorum discount, the 50% deposit will be applied to your account for
          future orders. There will be no refunds of your 50% deposit for
          Priorum discounted products once the order is confirmed. Changes made
          to the delivery date or pick-up date, or items returned late, will
          incur extended rental fees at the full listed rate on the Abbey Party
          Rents website, with no Priorum discounting.
        </p>
      </div>
    </div>
  );
};

export default FAQ;
