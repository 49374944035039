import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./checkListPage.css"; // Add CSS for styling

const CheckListPage = () => {
  const navigate = useNavigate();
  const checklistItems = [
    "I understand all rental items must be returned in the same condition.",
    "I acknowledge that I am responsible for any damages to the items.",
    "I agree to the rental terms and conditions.",
    "I confirm that delivery and pickup times will be honored.",
    "I will ensure that rented items are used appropriately.",
  ];

  const [checkedItems, setCheckedItems] = useState(
    new Array(checklistItems.length).fill(false)
  );

  const handleCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);
  };

  const allChecked = checkedItems.every((item) => item);

  return (
    <div className="checklist-container">
      <h1>Rental Agreement Checklist</h1>
      <ul>
        {checklistItems.map((item, index) => (
          <li key={index}>
            <label>
              <input
                type="checkbox"
                checked={checkedItems[index]}
                onChange={() => handleCheckboxChange(index)}
              />
              {item}
            </label>
          </li>
        ))}
      </ul>
      {allChecked && (
        <button className="go-to-home-btn" onClick={() => navigate("/home")}>
          Go to Priorum
        </button>
      )}
    </div>
  );
};

export default CheckListPage;
